import React from 'react'
import { iconDownload } from './images'

const PressReleases = () => {
  return (
    <div className='press-release'>
      <div className='press-release-title'>
        <h1>SoundBird App Press Releases</h1>
      </div>
      <div className="press-release-divider">
      </div>
      <div className='press-release-li-title'>
        SoundBird App Press Releases
      </div>
      <div className='download-button'>
        <div className='download-page'>
          <a href="https://docs.google.com/viewer?url=http%3A%2F%2Flocalhost%2Fsoundfly%2Fwp-content%2Fuploads%2F2019%2F03%2FSoundfly-App-Press-Release-1.pages">
            <img src={iconDownload} alt=""></img>
            Download .pages
          </a>
        </div>
        <div className='download-btn-divider'></div>
        <div className='download-doc'>
          <a href="https://docs.google.com/viewer?url=http%3A%2F%2Flocalhost%2Fsoundfly%2Fwp-content%2Fuploads%2F2019%2F03%2FSoundfly-App-Press-Release.docx">
            <img src={iconDownload} alt =""></img>Download .doc
          </a>
        </div>
      </div>
      <div className='download-screenshot'>
        Download screenshots from the app
      </div>
      <div className='download-button'>
        <div className='download-zip'>
          <a href="https://docs.google.com/viewer?url=http%3A%2F%2Flocalhost%2Fsoundfly%2Fwp-content%2Fuploads%2F2019%2F03%2FSoundfly-App-Press-Release-1.pages">
            <img src={iconDownload} alt=""></img>
          Download .zip
        </a>
        </div>
      </div>
    </div>

  )
}

export default PressReleases