import React from "react";

const Features = () => {
  return (
    <section className="features-section"
    data-aos="fade-up"	
    data-aos-duration="1000">
      <div className="features-content">
        <p>
          <i>
            <span>T</span> his is one of the few apps of its kind that actually
            works as <br /> described. Seems well thought out. Looking forward
            to more <br /> features.{" "}
          </i>
        </p>
        <h3> - bitbrain</h3>
      </div>
    </section>
  );
};

export default Features;
