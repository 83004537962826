import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { iconHeaderLogo } from "../images";

const Header = () => {
  const [showBasic, setShowBasic] = useState(false);
  return (
    <header>
      <div className="container">
        <div className="header-grip">
          <nav>
            <div className="logo">
              <NavLink to="/">
                <img src={iconHeaderLogo} alt="logo"/>
              </NavLink>
            </div>
            <div className="menu-icon" onClick={() => setShowBasic(!showBasic)}>
              <img src={showBasic ? "../close.png" : "../menu.png"} alt="" />
            </div>
            <div className={showBasic ? "menu active" : "menu"}>
              <li>
                <NavLink to="/press-releases">Press Releases</NavLink>
              </li>
              <li>
                <NavLink to="/videos">Videos</NavLink>
              </li>
              <a className="btn" href="#footer">
                Get SoundBird
              </a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
