import "./App.css";
import React, { useEffect } from 'react';
import { Header, Footer } from "./components";
import Videos from "./Videos";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import PressReleases from "./PressReleases";
import AOS from "aos";	
import "aos/dist/aos.css";	
import WOW from 'wowjs';
function App() {
  useEffect(() => {	
    AOS.init({	
      easing: "ease-out-cubic",	
      once: true,	
      offset: 50,	
    });	
    new WOW.WOW({	
      live: false	
  }).init();	
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/press-releases" element={<PressReleases />} />Z
          <Route path="/videos" element={<Videos />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
