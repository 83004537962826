import React from "react";
import { iconAppStore, iconGooglePlay, imgMicrophone } from "../images";

const Microphone = () => {
  return (
    <div className="microphone">
      <div className="container">
        <div className="grid">
          <div className="grid-slider">
            <div>
              <img src={imgMicrophone} alt="" />
            </div>
          </div>
          <div className="grid-6">
            <div className="soundbird-item textpadding">
              <h3 className="header-font" data-aos={"fade-down"} data-aos-duration={1500}>No microphone system?<br/> No problem.</h3>
              <p data-aos={"fade-up"} data-aos-duration={1000}>
                SoundBird allows you to broadcast your voice to an unlimited
                number of iphones with the app.
              </p>
              <div className="social-link">
                <a href="https://apps.apple.com/us/app/sound-bird-microphone/id1444546036">
                  <img src={iconAppStore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soundbird&hl=en_US">
                  <img src={iconGooglePlay} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Microphone;
