import React from "react";
import { iconAppStore, iconGooglePlay, imgBroadcast } from "../images";

const Broadcasts = () => {
  return (
    <div className="broadcasts">
      <div className="container">
        <div className="grid">
          <div className="grid-6">
            <div className="soundbird-item">
              <h3 data-aos={"fade-down"} data-aos-duration={1500} className="header-font">
                Listen to broadcasts for free! No time limit for listeners.
              </h3>
              <p data-aos={"fade-up"} data-aos-duration={1000}>
                SoundBird aims to be the voice of the people. Use it at an
                outdoor event, in a large room, to guide a tour, at a large
                gathering like a party, or any situation where there is an
                audience with smartphones.
              </p>
              <div className="social-link">
                <a href="https://apps.apple.com/us/app/sound-bird-microphone/id1444546036">
                  <img src={iconAppStore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soundbird&hl=en_US">
                  <img src={iconGooglePlay} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="grid-slider">
            <div>
              <img src={imgBroadcast} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Broadcasts;
