import React from "react";

const Download = () => {
  return (
    <section className="download-section"
    data-aos="fade-up"	
      data-aos-duration="1000">
      <div className="container">
        <div className="grid">
          <div className="download-content">
            <h2>Download the app for FREE!</h2>
            <h5>*The free version of the app can broadcast to 5 listeners</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
