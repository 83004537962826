import React from "react";

const Travel = () => {
  return (
    <section className="features-section">
      <div className="features-content">
        <p>
          <i>
            <span>S</span> o many uses! As a teacher and a travel group leader,
            this is godsend. It makes <br />
            communicating so much easier.
          </i>
        </p>
        <h3> - SOUNDBIRD user</h3>
      </div>
    </section>
  );
};

export default Travel;
