import React from 'react'
import {
    SoundBird,
    Only,
    Broadcasts,
    Microphone,
    Download,
    Hero,
    Features,
    Travel,
  } from "./components";
const Home = () => {
  return (
    <>
      <Hero />
      <Microphone />
      <Features />
      <Broadcasts />
      <Travel />
      <SoundBird />
      <Download />
      <Only />
    </>
  )
}

export default Home