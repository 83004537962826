import React from "react";
import Slider from "react-slick";
import { imgSound1, imgSound2 } from "../images";

const SoundBird = () => {
  const settings = {
    speed: 500,
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="Soundbird">
      <div className="container">
        <div className="heading-tab">
          <h2>
            How does
            <b>
              <span style={{ color: "#00adef" }}>SOUND</span><span style={{ color: "#fcb041" }}>BIRD</span>
            </b>
            work?
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000">First, everyone connects to the internet and downloads the app.</p>
        </div>
        <div className="grid">
          <div className="grid-slider">
            <Slider {...settings} arrows={false}>
              <div>
                <img src={imgSound1} alt="" />
              </div>
              <div>
                <img src={imgSound2} alt="" />
              </div>
            </Slider>
          </div>
          <div className="grid-6">
            <div className="soundbird-item">
              <h3 className="header-font">To broadcast your voice:</h3>
              <ul>
                <li>Open the SoundBird app on your phone</li>
                <li>Press Start Broadcast</li>
                <li>Select Audio Microphone or Video</li>
                <li>Name your broadcast session</li>
                <li>Press start</li>
              </ul>
            </div>
            <div className="soundbird-item">
              <h3 className="header-font">To listen to a broadcast:</h3>
              <ul>
                <li>Open the SoundBird app on your phone</li>
                <li>View list of nearby broadcasts</li>
                <li>Select a profile image</li>
                <li>Listen</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundBird;
