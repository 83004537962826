import React from "react";
import YoutubeEmbed from "./youtubeEmbed";

const Videos = () => {
  return (
    <div className="grid videos">
      <div className="grid-12">
        <h2>SoundBird Videos</h2>
      </div>
      <div className="videos grid-12">
        <YoutubeEmbed embedId="YSVrsZDc-Dg" />
        <p></p>
        <YoutubeEmbed embedId="ekvKf3fHHLA" />
        <p></p>
        <YoutubeEmbed embedId="v8t7XRq5GAs" />
      </div>
    </div>
  );
};

export default Videos;
