import React from "react";
import { iconAppStore, iconGooglePlay, imgHero1, imgHero2 } from "../images";

const Hero = () => {
  return (
    <div className="hero  wow fadeIn" data-wow-delay="0.2s">
      <div className="container">
        <div className="grid">
          <div className="grid-6">
            <div className="soundbird-item">
              <h3 className="font-hero">
                Broadcast to
                <br />
                nearby phones
              </h3>
              <p>
                Use your phone as a microphone and the phones of your audience
                as speakers.
              </p>
              <div className="social-link">
                <a href="https://apps.apple.com/us/app/sound-bird-microphone/id1444546036">
                  <img src={iconAppStore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.soundbird&hl=en_US">
                  <img src={iconGooglePlay} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="hero-img">
            <div className="hero-group-img">
              <img src={imgHero1} alt="" className="first-img" />
              <img src={imgHero2} alt="" className="second-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
