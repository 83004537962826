import React from "react";
import { NavLink } from "react-router-dom";

const Only = () => {
  return (
    <>
      <div className="section-background"></div>
      <section className="only-section">
        <div className="only-box">
          <div>
            <div className="only-content">
                <span className="header">
                  <h2>For only</h2>
                </span>
                <span className="pricing-tag-elem">
                  <span className="price-tag">
                    <span className="original-price">
                      <span className="currency">$</span>
                      10.49
                    </span>
                    <span className="price-period">/ month</span>
                  </span>
                </span>
                <p>You will get unlimited broadcasting!</p>
                <div className="only-btn">
                <NavLink to="/" className="btn">
                    Get me to the app
                </NavLink>
                </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-background-bottom"></div>
    </>
  );
};

export default Only;
